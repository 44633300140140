import Simple from '@/components/task-templates/Simple'
import CodeTest from '@/components/task-templates/CodeTest'

// функции-генераторы

export default [
  {
    component: CodeTest,
    lang: 'js',
    name: 'Умножение на 2',
    text: 'Напишите функцию \\(mul2\\), которая принимает число \\(a\\) в качестве параметра, и возвращает это число, умноженное на 2',
    note: 'Задачи, в которых указано название функии, называть не taskX, а так, как указано. Над самой функцией в комментарии нужно указать номер задания',
    examples: [
      {
        input: `
          console.log(mul2(1))
          console.log(mul2(3))
        `,
        output: `
          2
          6
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов mul2',
    text: 'Дано число \\(a\\). Выведите \\(4 * a\\) и \\(6 * a\\), используя функцию \\(mul2\\). Допускается одно сложение',
    note: null,
    input: '\\(a\\) - целое',
    output: 'Два числа через пробел',
    examples: [
      {
        input: '2',
        output: '8 12'
      },
      {
        input: '-1',
        output: '-4 -6'
      },
      {
        input: '5',
        output: '20 30'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Сумма двух чисел',
    text: 'Напишите функцию \\(sumPair\\), которая принимает числа \\(a\\) и \\(b\\), возвращает их сумму',
    note: null,
    examples: [
      {
        input: `
          console.log(sumPair(1, 2))
          console.log(sumPair(5, -3))
        `,
        output: `
          3
          2
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов sumPair',
    text: 'Даны числа \\(a\\) и \\(b\\). Используя функцию \\(sumPair\\), выведите \\(2 * (a + b)\\), не используя умножение, а затем через пробел разность \\(a\\) и \\(b\\), не используя вычитание',
    note: null,
    input: '\\(a\\) и \\(b\\) - целые',
    output: 'Два числа через пробел',
    examples: [
      {
        input: '5, 3',
        output: '16 2'
      },
      {
        input: '10, 20',
        output: '60 -10'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Чётность',
    text: 'Напишите функцию \\(isEven\\), которая принимает число \\(a\\) и возвращает true, если оно чётное, либо false, если нечётное',
    note: null,
    examples: [
      {
        input: `
          console.log(isEven(5))
          console.log(isEven(6))
        `,
        output: `
          false
          true
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов isEven',
    text: 'Даны числа \\(a\\), \\(b\\) и \\(c\\). Используя функцию \\(isEven\\), выведите, являются ли \\(a\\), \\(b\\) и \\(c\\) одновременно чётными. Затем, есть ли среди них хотя бы одно нечётное. Затем, одинаковая ли чётность у всех чисел',
    note: null,
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые',
    output: 'true и false по одному на строку',
    examples: [
      {
        input: '5, 3, 7',
        output: 'false\ntrue\ntrue'
      },
      {
        input: '4, 2, 6',
        output: 'true\nfalse\ntrue'
      },
      {
        input: '5, 1, 2',
        output: 'false\ntrue\nfalse'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Оканчивается на 4 или 7',
    text: 'Напишите функцию \\(endsWith47\\), которая принимает целое число \\(a\\) (\\(a >= 0\\)) и возвращает true, если оно оканчивается на 4 или 7, в противном случае false',
    note: null,
    examples: [
      {
        input: `
          console.log(endsWith47(7))
          console.log(endsWith47(14))
          console.log(endsWith47(21))
          console.log(endsWith47(70))
          console.log(endsWith47(27))
          console.log(endsWith47(304))
        `,
        output: `
          true
          true
          false
          false
          true
          true
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов endsWith47',
    text: 'Даны числа \\(a\\), \\(b\\) и \\(c\\). Используя функцию \\(endsWith47\\), выведите, сколько из чисел \\(a\\), \\(b\\) и \\(c\\) заканчиваются на 4 или 7. Затем, верно ли, что \\(a\\) и \\(b\\) заканчиваются на 4 или 7, а \\(c\\) - нет',
    note: null,
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые неотрицательные',
    output: 'Число в первой строке, true или false во второй',
    examples: [
      {
        input: '5, 3, 7',
        output: '1\nfalse'
      },
      {
        input: '14, 17, 5',
        output: '2\ntrue'
      },
      {
        input: '4, 804, 77',
        output: '3\nfalse'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Модуль',
    text: 'Напишите функцию \\(abs\\), которая принимает целое число \\(a\\) и возвращает модуль этого числа (убирает минус, если он есть)',
    note: null,
    examples: [
      {
        input: `
          console.log(abs(10))
          console.log(abs(-14))
          console.log(abs(-0))
          console.log(abs(0))
        `,
        output: `
          10
          14
          0
          0
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Знак',
    text: 'Напишите функцию \\(sign\\), которая принимает целое число \\(a\\) и возвращает -1, если оно отрицательное, 0, если равно нулю, и 1, если положительное',
    note: 'Не использовать else',
    examples: [
      {
        input: `
          console.log(sign(10))
          console.log(sign(-14))
          console.log(sign(-0))
          console.log(sign(0))
        `,
        output: `
          1
          -1
          0
          0
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Равенство массивов',
    text: 'Напишите функцию \\(arraysEqual\\), которая принимает два целочисленных массива и возвращает true, если они одинаковые, и false, если разные',
    note: 'Обёртку для массивов больше не используем, поэтому внимательнее с выходами за границы',
    examples: [
      {
        input: `
          console.log(arraysEqual([1, 2, 3], [1, 2, 3]))
          console.log(arraysEqual([3, 2, 1], [1, 2, 3]))
          console.log(arraysEqual([1, 2, 3], [1, 2]))
          console.log(arraysEqual([1, 2], [1, 2, 3]))
        `,
        output: `
          true
          false
          false
          false
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Префикс или нет?',
    text: 'Напишите функцию \\(startsWith\\), которая принимает два целочисленных массива и возвращает true, если первый начинается со второго и содержит все его элементы, false в противном случае',
    note: null,
    examples: [
      {
        input: `
          console.log(startsWith([1, 2, 3], [1, 2, 3]))
          console.log(startsWith([1, 2, 3], [1, 2]))
          console.log(startsWith([1, 2, 3], []))
          console.log(startsWith([1, 2, 3], [2, 3]))
          console.log(startsWith([1, 2, 3], [1, 3]))
          console.log(startsWith([1, 2, 3], [1, 2, 3, 4]))
        `,
        output: `
          true
          true
          true
          false
          false
          false
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Суффикс или нет?',
    text: 'Напишите функцию \\(endsWith\\), которая принимает два целочисленных массива и возвращает true, если хвост первого совпадает со вторым массивом и содержит все его элементы, false в противном случае',
    note: null,
    examples: [
      {
        input: `
          console.log(endsWith([1, 2, 3], [1, 2, 3]))
          console.log(endsWith([1, 2, 3], [1, 2]))
          console.log(endsWith([1, 2, 3], []))
          console.log(endsWith([1, 2, 3], [2, 3]))
          console.log(endsWith([1, 2, 3], [1, 3]))
          console.log(endsWith([1, 2, 3], [1, 2, 3, 4]))
        `,
        output: `
          true
          false
          true
          true
          false
          false
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Spread в аргументах',
    text: 'Дан массив \\(a\\), состоящий из двух чисел. Используя функцию \\(sumPair\\) выведите сумму элементов этого массива. Для передачи аргументов в \\(sumPair\\) использовать оператор spread',
    note: null,
    input: '\\(a\\) - массив из двух целых чисел',
    output: 'Сумма',
    examples: [
      {
        input: '[1, 2]',
        output: '3'
      },
      {
        input: '[3, 4]',
        output: '7'
      }
    ]
  },
  {
    component: Simple,
    name: 'Цикл вызовов со spread в аргументах',
    text: 'Дан массив \\(a\\), состоящий из пар чисел. Каждая пара - массив длины 2. Используя функцию \\(sumPair\\) выведите сумму каждой пары. Для передачи аргументов в \\(sumPair\\) использовать оператор spread',
    note: null,
    input: '\\(a\\) - целочисленная матрица ?x2',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '[[1, 2]]',
        output: '3'
      },
      {
        input: '[[3, 4], [5, 6]]',
        output: '7\n11'
      },
      {
        input: '[[7, 6], [5, 4], [3, 2]]',
        output: '13\n9\n5'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Rest в параметрах - sum',
    text: 'Напишите функцию \\(sum\\), которая принимает неизвестное количество параметров-чисел и возвращает их сумму',
    note: null,
    examples: [
      {
        input: `
          console.log(sum())
          console.log(sum(1))
          console.log(sum(1, 2))
          console.log(sum(2, 1, 3))
        `,
        output: `
          0
          1
          3
          6
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов sum',
    text: 'Дан целочисленный массив \\(a\\). С помощью функции \\(sum\\) выведите его сумму',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Сумма',
    examples: [
      {
        input: '[4, 2, 5]',
        output: '11'
      },
      {
        input: '[7, 2, 3]',
        output: '12'
      },
      {
        input: '[-1]',
        output: '-1'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Rest в параметрах - max',
    text: 'Напишите функцию \\(max\\), которая принимает неизвестное количество параметров-чисел и возвращает самое большое из них',
    note: 'Проще всего разложить параметры на первый и остальные',
    examples: [
      {
        input: `
          console.log(max(1))
          console.log(max(1, 2))
          console.log(max(2, 1))
          console.log(max(2, 1, 3))
          console.log(max(2, 3, 1))
          console.log(max(-2, -3, -1))
        `,
        output: `
          1
          2
          2
          3
          3
          -1
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов max',
    text: 'Дан целочисленный массив \\(a\\). С помощью функции \\(max\\) выведите самое большое число в нём',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Максимум',
    examples: [
      {
        input: '[4, 2, 5]',
        output: '5'
      },
      {
        input: '[7, 2, 3]',
        output: '7'
      },
      {
        input: '[-1]',
        output: '-1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Передача функции в параметры + массив',
    text: 'Дана функция \\(f\\), а также массив её аргументов \\(args\\). Выведите результат вызова функции, передав ей аргументы из массива',
    note: null,
    input: '\\(f\\) - функция, \\(args\\) - массив',
    output: 'Результат вызова функции',
    examples: [
      {
        input: 'mul2, [7]',
        output: '14'
      },
      {
        input: 'sumPair, [4, 3]',
        output: '7'
      },
      {
        input: 'isEven, [3]',
        output: 'false'
      },
      {
        input: 'endsWith47, [74]',
        output: 'true'
      },
      {
        input: 'abs, [-22]',
        output: '22'
      },
      {
        input: 'sign, [-22]',
        output: '-1'
      },
      {
        input: 'startsWith, [[1, 2, 3], [1, 2]]',
        output: 'true'
      },
      {
        input: 'endsWith, [[1, 2, 3], [1, 2]]',
        output: 'false'
      },
      {
        input: 'sum, [1, 2, 3]',
        output: '6'
      },
      {
        input: 'max, [3, 2, 1]',
        output: '3'
      }
    ]
  },
  {
    component: Simple,
    name: 'Передача функции в параметры + аргументы',
    text: 'Дана функция \\(f\\), а также все её аргументы \\(args\\). Выведите результат вызова функции, передав ей все аргументы',
    note: null,
    input: '\\(f\\) - функция, \\(args\\) - все аргументы',
    output: 'Результат вызова функции',
    examples: [
      {
        input: 'mul2, 7',
        output: '14'
      },
      {
        input: 'sumPair, 4, 3',
        output: '7'
      },
      {
        input: 'isEven, 3',
        output: 'false'
      },
      {
        input: 'endsWith47, 74',
        output: 'true'
      },
      {
        input: 'abs, -22',
        output: '22'
      },
      {
        input: 'sign, -22',
        output: '-1'
      },
      {
        input: 'startsWith, [1, 2, 3], [1, 2]',
        output: 'true'
      },
      {
        input: 'endsWith, [1, 2, 3], [1, 2]',
        output: 'false'
      },
      {
        input: 'sum, 1, 2, 3',
        output: '6'
      },
      {
        input: 'max, 3, 2, 1',
        output: '3'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'forEach',
    text: 'Напишите функцию \\(forEach\\), которая принимает целочисленный массив \\(a\\) и функцию \\(f\\). Функция \\(f\\) принимает одно число и что-то делает, ничего не возвращает. Функция \\(forEach\\) должна вызывать функцию \\(f\\) на каждом элементе \\(a\\)',
    note: null,
    examples: [
      {
        input: `
          forEach([4, 2, 1], (n) => {
              console.log(n * 2)
          })
          console.log('---')
          let s = 0
          forEach([4, 2, 1], (n) => {
              s += n
          })
          console.log(s)
          console.log('---')
          forEach([10, 20], console.log)
        `,
        output: `
          8
          4
          2
          ---
          7
          ---
          10
          20
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов forEach',
    text: 'Дан целочисленный массив \\(a\\). С помощью функций \\(forEach\\) и \\(endsWith47\\) посчитайте, сколько чисел из этого массива заканчиваются на 4 или 7',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Количество чисел *4/*7',
    examples: [
      {
        input: '[4, 2, 5]',
        output: '1'
      },
      {
        input: '[7, 14, 27]',
        output: '3'
      },
      {
        input: '[17, 71, 41, 14]',
        output: '2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов нативного forEach',
    text: 'Дан целочисленный массив \\(a\\). С помощью метода массива \\(forEach\\) и \\(endsWith47\\) посчитайте, сколько чисел из этого массива заканчиваются на 4 или 7',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Количество чисел *4/*7',
    examples: [
      {
        input: '[4, 2, 5]',
        output: '1'
      },
      {
        input: '[7, 14, 27]',
        output: '3'
      },
      {
        input: '[17, 71, 41, 14]',
        output: '2'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'find',
    text: 'Напишите функцию \\(find\\), которая принимает целочисленный массив \\(a\\) и функцию \\(f\\). Функция \\(f\\) принимает одно число и возвращает boolean. Функция \\(find\\) должна вернуть первое число из массива \\(a\\), для которого функция \\(f\\) вернёт true. Если такого числа нет, вернуть undefined',
    note: null,
    examples: [
      {
        input: `
          console.log(find([3, 5, 6, 7], isEven))
          console.log(find([22, 34, 37, 14], endsWith47))
          console.log(find([1, 2, 3], (x) => x < 0))
        `,
        output: `
          6
          34
          undefined
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов find',
    text: 'Дан целочисленный массив \\(a\\). С помощью функций \\(find\\) выведите первое число, делящееся на 3, а затем на следующей строке первое число, которое больше 10',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Первое кратное 3 и первое, большее 10',
    examples: [
      {
        input: '[4, 2, 5]',
        output: 'undefined\nundefined'
      },
      {
        input: '[2, 9, 27]',
        output: '9\n27'
      },
      {
        input: '[17, 5, 6, 3]',
        output: '6\n17'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов нативного find',
    text: 'Дан целочисленный массив \\(a\\). С помощью метода массива \\(find\\) выведите первое число, делящееся на 3, а затем на следующей строке первое число, которое больше 10',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Первое кратное 3 и первое, большее 10',
    examples: [
      {
        input: '[4, 2, 5]',
        output: 'undefined\nundefined'
      },
      {
        input: '[2, 9, 27]',
        output: '9\n27'
      },
      {
        input: '[17, 5, 6, 3]',
        output: '6\n17'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'findIndex',
    text: 'Напишите функцию \\(findIndex\\), которая принимает целочисленный массив \\(a\\) и функцию \\(f\\). Функция \\(f\\) принимает одно число и возвращает boolean. Функция \\(findIndex\\) должна вернуть индекс первого числа из массива \\(a\\), для которого функция \\(f\\) вернёт true. Если такого числа нет, вернуть -1',
    note: null,
    examples: [
      {
        input: `
          console.log(findIndex([3, 5, 6, 7], isEven))
          console.log(findIndex([22, 34, 37, 14], endsWith47))
          console.log(findIndex([1, 2, 3], (x) => x < 0))
        `,
        output: `
          2
          1
          -1
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов findIndex',
    text: 'Дан целочисленный массив \\(a\\). С помощью функций \\(findIndex\\) выведите индекс первого числа, делящегося на 3, а затем на следующей строке выведите индекс первого числа, которое больше 10',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Первое кратное 3 и первое, большее 10',
    examples: [
      {
        input: '[4, 2, 5]',
        output: '-1\n-1'
      },
      {
        input: '[2, 9, 27]',
        output: '1\n2'
      },
      {
        input: '[17, 5, 6, 3]',
        output: '2\n0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов нативного findIndex',
    text: 'Дан целочисленный массив \\(a\\). С помощью метода массива \\(findIndex\\) выведите индекс первого числа, делящегося на 3, а затем на следующей строке выведите индекс первого числа, которое больше 10',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Первое кратное 3 и первое, большее 10',
    examples: [
      {
        input: '[4, 2, 5]',
        output: '-1\n-1'
      },
      {
        input: '[2, 9, 27]',
        output: '1\n2'
      },
      {
        input: '[17, 5, 6, 3]',
        output: '2\n0'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'map',
    text: 'Напишите функцию \\(map\\), которая принимает целочисленный массив \\(a\\) и функцию \\(f\\). Функция \\(f\\) принимает одно число и что-то возвращает. Функция \\(map\\) должна вызывать функцию \\(f\\) на каждом элементе \\(a\\), и из результатов составить новый массив',
    note: null,
    examples: [
      {
        input: `
          console.log(map([4, 2, 1], mul2))
          console.log(map([4, 2, 9], isEven))
          console.log(map([4, 2, 9], (x) => x % 2))
        `,
        output: `
          [8, 4, 2]
          [true, true, false]
          [0, 0, 1]
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов map',
    text: 'Дан целочисленный массив \\(a\\). С помощью функции \\(map\\) выведите массив, в котором каждый элемент \\(a\\) будет неотрицательным (используя модуль). Затем, во второй строке ещё один массив, в котором будет накопительная сумма элементов \\(a\\) (первый элемент совпадает, а каждый следующий - сумма предыдущих + следующий элемент из \\(a\\))',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Массив с модулями и массив с накопительной суммой',
    examples: [
      {
        input: '[1, 2, -3]',
        output: '[1, 2, 3]\n[1, 3, 0]'
      },
      {
        input: '[-1, 2, -3]',
        output: '[1, 2, 3]\n[-1, 1, -2]'
      },
      {
        input: '[1, -2, 3, -4]',
        output: '[1, 2, 3, 4]\n[1, -1, 2, -2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов нативного map',
    text: 'Дан целочисленный массив \\(a\\). С помощью метода массива \\(map\\) выведите массив, в котором каждый элемент \\(a\\) будет неотрицательным (используя модуль). Затем, во второй строке ещё один массив, в котором будет накопительная сумма элементов \\(a\\) (первый элемент совпадает, а каждый следующий - сумма предыдущих + следующий элемент из \\(a\\))',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Массив с модулями и массив с накопительной суммой',
    examples: [
      {
        input: '[1, 2, -3]',
        output: '[1, 2, 3]\n[1, 3, 0]'
      },
      {
        input: '[-1, 2, -3]',
        output: '[1, 2, 3]\n[-1, 1, -2]'
      },
      {
        input: '[1, -2, 3, -4]',
        output: '[1, 2, 3, 4]\n[1, -1, 2, -2]'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'count',
    text: 'Напишите функцию \\(count\\), которая принимает целочисленный массив \\(a\\) и функцию \\(f\\). Функция \\(f\\) принимает одно число и возвращает boolean. Функция \\(count\\) должна вернуть количество элементов массива \\(a\\), для которых функция \\(f\\) вернёт true',
    note: null,
    examples: [
      {
        input: `
          console.log(count([10], () => true))
          console.log(count([4, 2, 1], isEven))
          console.log(count([4, 2, 9, 74], endsWith47))
          console.log(count([-1, 2, -3, 5, 1], (x) => x > 0))
        `,
        output: `
          1
          2
          2
          3
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов count',
    text: 'Дан целочисленный массив \\(a\\). С помощью функции \\(count\\) выведите количество чётных элементов \\(a\\), а затем на следующей строке количество делящихся на 2 или 3',
    note: 'К сожалению, такого метода у массивов нет',
    input: '\\(a\\) - массив из целых чисел',
    output: 'Количество чётных и количество делящихся на 2 или 3',
    examples: [
      {
        input: '[2, 4, 3, 5]',
        output: '2\n3'
      },
      {
        input: '[6, 8, 9, 10, 11, 12]',
        output: '4\n5'
      },
      {
        input: '[3, 9, 27, 81]',
        output: '0\n4'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'filter',
    text: 'Напишите функцию \\(filter\\), которая принимает целочисленный массив \\(a\\) и функцию \\(f\\). Функция \\(f\\) принимает одно число и возвращает boolean. Функция \\(filter\\) должна создать новый массив, в котором будут только те элементы \\(a\\), для которых функция \\(f\\) вернёт true',
    note: 'Расширение массива в задаче не допускается. Желательно не вызывать функцию \\(f\\) на одном элементе несколько раз',
    examples: [
      {
        input: `
          console.log(filter([4, 2, 1], isEven))
          console.log(filter([4, 2, 9, 74], endsWith47))
          console.log(filter([-1, 2, -3, 5, 1], (x) => x > 0))
        `,
        output: `
          [4, 2]
          [4, 74]
          [2, 5, 1]
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов filter',
    text: 'Дан целочисленный массив \\(a\\). С помощью функции \\(filter\\) выведите массив, в котором будут содержаться только нечётные элементы \\(a\\). Затем ещё один массив, в котором будет только каждый второй элемент из \\(a\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Массив с нечётными элементами и массив с каждым вторым элементом \\(a\\)',
    examples: [
      {
        input: '[2, 4, 3, 5]',
        output: '[3, 5] [4, 5]'
      },
      {
        input: '[1, 2, 3, 3, 2, 1]',
        output: '[1, 3, 3, 1] [2, 3, 1]'
      },
      {
        input: '[2, 4, 8, 16, 32]',
        output: '[] [4, 16]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов нативного filter',
    text: 'Дан целочисленный массив \\(a\\). С помощью метода массива \\(filter\\) выведите массив, в котором будут содержаться только нечётные элементы \\(a\\). Затем ещё один массив, в котором будет только каждый второй элемент из \\(a\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Массив с нечётными элементами и массив с каждым вторым элементом \\(a\\)',
    examples: [
      {
        input: '[2, 4, 3, 5]',
        output: '[3, 5] [4, 5]'
      },
      {
        input: '[1, 2, 3, 3, 2, 1]',
        output: '[1, 3, 3, 1] [2, 3, 1]'
      },
      {
        input: '[2, 4, 8, 16, 32]',
        output: '[] [4, 16]'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'reduce',
    text: 'Напишите функцию \\(reduce\\), которая принимает целочисленный массив \\(a\\) и функцию \\(f\\). Функция \\(f\\) принимает два числа и возвращает число. Функция \\(reduce\\) должна последовательно применить функцию \\(f\\), передавая первым аргументом результат предыдущего шага и вторым аргументом следующее число из \\(a\\). На первом шаге за результат предыдущего шага взять первый элемент из \\(a\\). Вернуть последнее полученное значение',
    note: 'Например, если взять массив [1, 2, 5] и функцию sumPair, на первом шаге вызывается sumPair(1, 2), получается 3, на втором шаге вызывается sumPair(3, 5), получается 8, больше элементов нет, 8 нужно вернуть',
    examples: [
      {
        input: `
          console.log(reduce([1, 2, 5], sumPair))
          console.log(reduce([2, 3, 2], (a, b) => a * b))
          console.log(reduce([3, 5, 2, 4], (a, b) => a > b ? a : b))
        `,
        output: `
          8
          12
          5
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов reduce',
    text: 'Дан целочисленный массив \\(a\\). С помощью функции \\(reduce\\) выведите наименьший элемент из \\(a\\), затем сумму каждого второго элемента, начиная с первого, на следующей строке',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Минимум и сумма элементов на нечётных позициях',
    examples: [
      {
        input: '[2, 4, 3, 5]',
        output: '2\n5'
      },
      {
        input: '[4, 7, 3, 3, 5, 9]',
        output: '3\n12'
      },
      {
        input: '[13, 8, 7, 17, 22]',
        output: '7\n42'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов нативного reduce',
    text: 'Дан целочисленный массив \\(a\\). С помощью метода массива \\(reduce\\) выведите наименьший элемент из \\(a\\), затем сумму каждого второго элемента, начиная с первого, на следующей строке',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Минимум и сумма элементов на нечётных позициях',
    examples: [
      {
        input: '[2, 4, 3, 5]',
        output: '2\n5'
      },
      {
        input: '[4, 7, 3, 3, 5, 9]',
        output: '3\n12'
      },
      {
        input: '[13, 8, 7, 17, 22]',
        output: '7\n42'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Замыкание на примере счётчика',
    text: 'Напишите функцию \\(createCounter\\), которая ничего не принимает, создаёт внутри себя переменную \\(count\\) со значением 0, затем возвращает новую функцию, которая тоже ничего не принимает, увеличивает значение \\(count\\) на 1, и возвращает \\(count\\)',
    note: null,
    examples: [
      {
        input: `
          const f1 = createCounter()
          const f2 = createCounter()
          console.log(f1())
          console.log(f1())
          console.log(f2())
          console.log(f2())
          console.log(f1(), f2())
        `,
        output: `
          1
          2
          1
          2
          3 3
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Замыкание на примере сумматора',
    text: 'Напишите функцию \\(createSummator\\), которая ничего не принимает, создаёт внутри себя переменную \\(sum\\) со значением 0, затем возвращает новую функцию, которая принимает число, увеличивает значение \\(sum\\) на это число, и возвращает \\(sum\\)',
    note: null,
    examples: [
      {
        input: `
          const f1 = createSummator()
          const f2 = createSummator()
          console.log(f1(4))
          console.log(f1(6))
          console.log(f2(2))
          console.log(f2(9))
          console.log(f1(3), f2(7))
        `,
        output: `
          4
          10
          2
          11
          13 18
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Замыкание на примере изменения состояния',
    text: 'Напишите функцию \\(createState\\), которая принимает какой-то параметр \\(state\\), затем возвращает массив из двух функций. Первая функция ничего не принимает, возвращает \\(state\\). Вторая принимает какой-то параметр и записывает его в переменную \\(state\\), ничего не возвращает',
    note: null,
    examples: [
      {
        input: `
          const [getValue1, setValue1] = createState(1)
          const [getValue2, setValue2] = createState(false)
          console.log(getValue1(), getValue2())
          console.log(getValue1(), getValue2())
          setValue1(2)
          console.log(getValue1(), getValue2())
          setValue2(true)
          console.log(getValue1(), getValue2())
        `,
        output: `
          1 false
          1 false
          2 false
          2 true
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Замыкание на примере частичного применения',
    text: 'Напишите функцию \\(partial\\), которая принимает произвольную функцию \\(f\\), плюс какое-то количество дополнительных параметров \\(args\\). Функция \\(partial\\) должна вернуть новую функцию, которая принимает второй набор параметров \\(args2\\), вызывает \\(f\\), передав в неё все аргументы из \\(args\\), затем все аргументы из \\(args2\\). Функция \\(partial\\) должна вернуть результат вызова \\(f\\)',
    note: null,
    examples: [
      {
        input: `
          const sum2 = partial(sumPair, 2)
          console.log(sum2(4))
          console.log(sum2(7))
          const max1 = partial(max, 4, 2, 5)
          const max2 = partial(max1, 1, 3)
          console.log(max2(3, 1))
          console.log(max2(3, 6))
        `,
        output: `
          6
          9
          5
          6
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Параметры по умолчанию',
    text: 'Дано одно или два числа \\(a\\) и \\(b\\). Если второе не указано, считать его за 2. Вывести произведение чисел',
    note: 'Параметры по умолчанию работают так же, как и при деструктуризации в массиве. Если для переменной значение не указано, либо равно undefined, то берётся значение по умолчанию, если оно есть. Если значения по умолчанию нет, в переменной будет undefined',
    input: '\\(a\\), \\(b\\) - целые числа',
    output: 'Произведение',
    examples: [
      {
        input: '3, 4',
        output: '12'
      },
      {
        input: '4',
        output: '8'
      },
      {
        input: '0',
        output: '0'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Пример рекурсии: факториал',
    text: 'Напишите функцию \\(factorial\\), которая принимает натуральное число \\(n\\) и возвращает произведение всех чисел от 1 до \\(n\\) включительно с помощью рекурсии',
    note: null,
    examples: [
      {
        input: `
          console.log(factorial(1))
          console.log(factorial(2))
          console.log(factorial(3))
          console.log(factorial(4))
          console.log(factorial(5))
          console.log(factorial(6))
        `,
        output: `
          1
          2
          6
          24
          120
          720
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Пример рекурсии: поиск элемента в массиве',
    text: 'Напишите функцию \\(includesRecursive\\), которая принимает целочисленный массив \\(a\\) и целое число \\(n\\), возвращает true, если \\(n\\) присутствует в массиве и false в противном случае',
    note: null,
    examples: [
      {
        input: `
          console.log(includesRecursive([5, 2, 3, 1], 3))
          console.log(includesRecursive([5, 2, 3, 1], 4))
        `,
        output: `
          true
          false
        `
      }
    ]
  }
]
